import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div class="intro">
      <div style={{ maxWidth: `200px`, marginBottom: `1.45rem` }}>
        <Image />
      </div>
      <div>
        <p>
          Hi! I'm Kabdo Choi. I was a member of <a href="https://www.kixlab.org/">KIXLAB</a> during my Master's.
        </p>
        <p>
          My research interests include Human-Computer Interaction, education, programming support, and learning at scale. I aim to build systems that make learning opportunities more accessible, especially for self-learners.
        </p>
        <p>
          I am currently looking for a Frontend developer position as 전문연구요원 (alternative military service).
        </p>
        <p>
          <a href="https://www.dropbox.com/s/rg7jsmj7hh3sbrv/CV_public.pdf?dl=0">Curriculum Vitae</a> | Contact: cyron1259@gmail.com
        </p>
      </div>
    </div>
    <hr/>
    <h2>Education</h2>
    <ul>
      <li>Mar '19 - Aug '21: M.S. in Computer Science, KAIST (Advisor: Juho Kim)</li>
      <li>Mar '14 - Feb '19: B.S. in Computer Science, KAIST (Minor: Culture Technology)</li>
    </ul>
    <hr/>
    <h2>Publications</h2>
    <p>
      [CHI 2022] <b>Kabdo Choi</b>, Hyungyu Shin, Meng Xia, and Juho Kim. AlgoSolve: Supporting Subgoal Learning in Algorithmic Problem-Solving with Learnersourced Microtasks. (to appear) <a href="https://www.dropbox.com/s/6b5gupwrs6vaxx9/chi22paper.pdf?dl=0">pdf</a>
    </p>
    <p>
      [L@S 2020 Work-in-Progress] <b>Kabdo Choi</b>, Sally Chen, Hyungyu Shin, Jinho Son, and Juho Kim. AlgoPlan: Supporting Planning in Algorithmic Problem-Solving with Subgoal Diagrams. <a href="https://kixlab.github.io/website-files/2020/l@s2020-AlgoPlan-poster.pdf">pdf</a>
    </p>
    <p>
      [SSBSE 2018] <b>Kabdo Choi</b>, Jeongju Sohn, and Shin Yoo. Learning Fault Localisation for both Humans and Machines Using Multi-objective GP. <a href="https://coinse.kaist.ac.kr/publications/pdfs/Choi2018aa.pdf">pdf</a>
    </p>
    <hr/>
  </Layout>
)

export default IndexPage
